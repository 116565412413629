import { graphql } from '@eluve/graphql.tada';

export const appointmentLlmOutputFragment = graphql(`
  fragment AppointmentLlmOutput on AppointmentLlmOutputs @_unmask {
    __typename
    appointmentId
    llmOutputId
    output {
      __typename
      id
      content
      status
      createdAt
      metadata {
        __typename
        llmOutputId
        prompt_template {
          outputType
        }
      }
    }
  }
`);

export const getLatestAppointmentLlmOutputByOutputTypeQuery = graphql(
  `
    query getLatestAppointmentOutputByOutputType(
      $tenantId: uuid!
      $appointmentId: uuid!
      $outputType: LlmOutputTypesEnum!
    ) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        appointment_llm_outputs(
          where: {
            output: {
              metadata: {
                prompt_template: { outputType: { _eq: $outputType } }
              }
            }
          }
          orderBy: { output: { createdAt: DESC } }
          limit: 1
        ) {
          ...AppointmentLlmOutput
        }
      }
    }
  `,
  [appointmentLlmOutputFragment],
);

export const listenForAppointmentLlmOutputsSubscription = graphql(`
  subscription AppointmentLlmOutputsSubscription(
    $tenantId: uuid!
    $appointmentId: uuid!
    $outputType: LlmOutputTypesEnum!
    $initialValue: timestamptz!
  ) {
    appointmentLlmOutputsStream(
      where: {
        tenantId: { _eq: $tenantId }
        appointmentId: { _eq: $appointmentId }
        output: {
          metadata: { prompt_template: { outputType: { _eq: $outputType } } }
        }
      }
      batchSize: 1
      cursor: { initialValue: { createdAt: $initialValue }, ordering: ASC }
    ) {
      __typename
      appointmentId
      llmOutputId
    }
  }
`);

export const listenForAppointmentLlmOutputStatusSubscription = graphql(`
  subscription AppointmentLlmOutputStatusSubscription(
    $tenantId: uuid!
    $appointmentId: uuid!
    $outputType: LlmOutputTypesEnum!
  ) {
    appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
      __typename
      id
      appointment_llm_jobs(
        where: { llmOutputType: { _eq: $outputType } }
        orderBy: { createdAt: DESC }
        limit: 1
      ) {
        __typename
        jobStatus
      }
    }
  }
`);
