import { graphql } from '@eluve/graphql.tada';

export const appointmentTagOptionsFragment = graphql(`
  fragment TagOptions on query_root {
    __typename
    appointmentTagOptions {
      __typename
      id
      tag
    }
  }
`);

export const appointmentTagsFragment = graphql(`
  fragment Tags on Appointments @_unmask {
    __typename
    id
    tags {
      __typename
      id
      tag {
        __typename
        id
        tag
      }
    }
  }
`);

export const appointmentLowQualityFactorsFragment = graphql(`
  fragment LowQualityFactors on Appointments @_unmask {
    __typename
    id
    low_quality_factors {
      __typename
      id
      factor
    }
  }
`);

export const appointmentAnnotationsFragment = graphql(`
  fragment Annotations on Appointments @_unmask {
    __typename
    id
    annotations(orderBy: { createdAt: ASC }) {
      __typename
      id
      userEmail
      createdAt
      annotation {
        __typename
        id
        annotation
      }
    }
  }
`);

export const searchAppointmentsQuery = graphql(
  `
    query searchAppointments(
      $filter: AppointmentsBoolExp
      $offset: Int
      $orderBy: [AppointmentsOrderBy!]
      $limit: Int
    ) {
      appointments(
        where: $filter
        offset: $offset
        limit: $limit
        orderBy: $orderBy
      ) {
        __typename
        id
        name
        status
        updatedAt
        user {
          __typename
          id
          email
        }
        tenant {
          __typename
          id
          name
        }
        annotationsAggregate {
          __typename
          aggregate {
            count
          }
        }
        ...LowQualityFactors
        ...Tags
      }
      appointmentsAggregate(where: $filter) {
        __typename
        aggregate {
          __typename
          count
        }
      }
    }
  `,
  [appointmentLowQualityFactorsFragment, appointmentTagsFragment],
);

export const searchFeedbackQuery = graphql(`
  query searchFeedback(
    $filter: SummaryFeedbackBoolExp
    $offset: Int
    $orderBy: [SummaryFeedbackOrderBy!]
    $limit: Int
  ) {
    summaryFeedback(
      where: $filter
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      __typename
      id
      rating
      tenantId
      type
      comment
      createdAt
      userId
      appointment {
        __typename
        id
        name
      }
      user {
        __typename
        id
        email
      }
      tenant {
        __typename
        id
        name
      }
      llm_output {
        __typename
        id
        modelPromptTemplate {
          __typename
          id
          model_args {
            __typename
            id
            name
          }
          template {
            __typename
            id
            name
          }
        }
      }
      summaryFeedbackTags {
        __typename
        feedbackTag {
          __typename
          id
          name
        }
      }
    }
    summaryFeedbackAggregate(where: $filter) {
      __typename
      aggregate {
        __typename
        count
      }
    }
  }
`);

export const reviewerTenantsFragment = graphql(`
  fragment ReviewerTenants on query_root {
    tenants {
      __typename
      id
      name
    }
  }
`);

export const reviewerUsersFragment = graphql(`
  fragment ReviewerUsers on query_root {
    users {
      __typename
      id
      email
    }
  }
`);

export const reviewerLowQualityFactorsFragment = graphql(`
  fragment ReviewerLowQualityFactors on query_root {
    lowQualityFactors {
      __typename
      name
      description
    }
  }
`);

export const reviewerPromptTemplatesFragment = graphql(`
  fragment ReviewerPromptTemplates on query_root {
    promptTemplates {
      __typename
      id
      name
    }
  }
`);

export const createAppointmentAnnotationMutation = graphql(`
  mutation createAppointmentAnnotation(
    $input: AppointmentAnnotationsInsertInput!
  ) {
    insertAppointmentAnnotationsOne(object: $input) {
      __typename
      id
      createdAt
      annotation {
        __typename
        id
        annotation
      }
    }
  }
`);

export const addLowQualityFactorMutation = graphql(`
  mutation addLowQualityFactor(
    $input: AppointmentLowQualityFactorsInsertInput!
  ) {
    insertAppointmentLowQualityFactorsOne(object: $input) {
      __typename
      id
      factor
    }
  }
`);

export const removeLowQualityFactorMutation = graphql(`
  mutation removeLowQualityFactor($id: uuid!) {
    deleteAppointmentLowQualityFactorsByPk(id: $id) {
      __typename
      id
    }
  }
`);

export const addAppointmentReviewLink = graphql(`
  mutation addAppointmentReviewLink(
    $input: AppointmentReviewLinksInsertInput!
  ) {
    insertAppointmentReviewLinksOne(object: $input) {
      __typename
      id
      link
    }
  }
`);

export const removeAppointmentReviewLinkMutation = graphql(`
  mutation removeAppointmentReviewLink($id: uuid!) {
    deleteAppointmentReviewLinksByPk(id: $id) {
      __typename
      id
    }
  }
`);

export const appointmentReviewLinksFragment = graphql(`
  fragment ReviewLinks on Appointments {
    __typename
    id
    review_links {
      __typename
      id
      link
      label
    }
  }
`);

export const appointmentFilesFragment = graphql(`
  fragment Files on Appointments @_unmask {
    __typename
    id
    appointmentFiles {
      __typename
      appointmentId
      fileId
      fileType
      userUpload {
        __typename
        id
        status
        fileUrl
        originalFileName
      }
    }
  }
`);

export const segmentUtterancesFragment = graphql(`
  fragment Utterances on AppointmentSegments @_unmask {
    __typename
    id
    utterances(orderBy: { startAt: ASC }) {
      __typename
      id
      speaker
      text
      startAt
      endAt
      updatedById
      fragments(orderBy: { startAt: ASC }) {
        __typename
        id
        text
        confidence
        startAt
        endAt
      }
    }
  }
`);

export const segmentFragment = graphql(
  `
    fragment Segment on AppointmentSegments @_unmask {
      __typename
      id
      transcript
      recordingStartedAt
      recordingDuration
      raw_transcript(where: { _and: [{ transcriptType: { _eq: BATCH } }] }) {
        __typename
        id
        metadata_file {
          __typename
          id
          fileUrl
        }
        user_upload {
          __typename
          id
          fileUrl
        }
      }
      ...Utterances
    }
  `,
  [segmentUtterancesFragment],
);

export const segmentsFragment = graphql(
  `
    fragment Segments on Appointments @_unmask {
      __typename
      id
      segments {
        ...Segment
      }
    }
  `,
  [segmentFragment],
);

export const getReviewerAppointmentDetails = graphql(
  `
    query getReviewerAppointmentDetails(
      $tenantId: uuid!
      $appointmentId: uuid!
    ) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        ...Files
        ...Segments
      }
    }
  `,
  [appointmentFilesFragment, segmentsFragment],
);

export const updateUtteranceTextMutation = graphql(`
  mutation updateUtteranceText($tenantId: uuid!, $id: uuid!, $text: String!) {
    updateUtterancesByPk(
      pkColumns: { tenantId: $tenantId, id: $id }
      _set: { text: $text }
    ) {
      __typename
      id
      text
      updatedById
    }
  }
`);
