import { useSubscription } from '@apollo/client';
import { useMemo } from 'react';

import { useAppointmentContext } from './appointment.context';
import { listenForAppointmentLlmOutputsSubscription } from './insights.operations';
import { SupportedOutputTypes } from './useLatestAppointmentLlmOutput';

export const useListenForAppointmentLlmOutputs = ({
  outputType,
  lastOutputCreatedAt,
  onDataReceived,
}: {
  outputType: SupportedOutputTypes;
  lastOutputCreatedAt: string | null;
  onDataReceived: () => void;
}) => {
  const { tenantId, appointmentId } = useAppointmentContext();

  const initialValue = useMemo(
    () => lastOutputCreatedAt ?? new Date().toISOString(),
    [lastOutputCreatedAt],
  );

  const { data } = useSubscription(listenForAppointmentLlmOutputsSubscription, {
    variables: {
      tenantId,
      appointmentId,
      outputType,
      initialValue,
    },
  });

  if (data?.appointmentLlmOutputsStream.length === 1) {
    onDataReceived();
  }
};
