import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const reviewerContract = c.router(
  {
    getAppointmentFileSignedUrl: {
      method: 'GET',
      path: '/file/:tenantId/:appointmentId/:fileId',
      responses: {
        200: z.object({
          presignedUrl: z.string().nullable(),
        }),
      },
      summary:
        'Requests a presigned GCS Url for downloading an appointment file',
    },
    prepareUtterances: {
      method: 'POST',
      path: '/:tenantId/:appointmentId/prepare-utterances',
      body: z.object({}),
      responses: {
        200: z.object({}),
      },
      summary:
        'Prepares utterances for the appointment by converting the raw metadata into structured data',
    },
  },
  { pathPrefix: '/review' },
);
