import { useMutation } from '@apollo/client';
import { useState } from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import {
  HStack,
  Icon,
  NewButton,
  P,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  VStack,
  textStyles,
  toast,
  tv,
} from '@eluve/components';
import {
  useAppointmentContext,
  useAppointmentStatus,
  useLatestAppointmentLlmOutput,
  useListenForAppointmentLlmOutputStatus,
} from '@eluve/frontend-appointment-hooks';
import { useListenForAppointmentLlmOutputs } from '@eluve/frontend-appointment-hooks';
import { DifferentialDiagnosesOutput } from '@eluve/frontend-feature-appointment';
import {
  FeedbackTypeLookup,
  LlmOutputTypesLookup,
  WorkerStatusTypesLookup,
} from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { RedFlagsOutputSchema } from '@eluve/llm-outputs';

import { RedFlagsOutput } from './RedFlagsOutput';

// Constants

const insertFeedbackMutation = graphql(`
  mutation insertSummaryFeedback(
    $appointmentId: uuid!
    $llmOutputId: uuid
    $type: FeedbackTypeEnum!
    $summarySection: String
  ) {
    insertSummaryFeedbackOne(
      object: {
        appointmentId: $appointmentId
        llmOutputId: $llmOutputId
        type: $type
        summarySection: $summarySection
      }
    ) {
      id
      type
      __typename
    }
  }
`);

// Private Components

type InsightTab = 'RED_FLAGS' | 'DIFFERENTIAL_DIAGNOSES';

const InsightTabLabels: { [key in InsightTab]: string } = {
  RED_FLAGS: 'Red Flags',
  DIFFERENTIAL_DIAGNOSES: 'Differential Diagnoses',
};

const insightTabButtonStyles = tv({
  base: textStyles.label({
    color: 'supporting',
    className:
      '-my-px border-b-2 border-transparent px-2 py-3 uppercase hover:text-contentTertiary',
  }),
  variants: {
    active: {
      true: 'border-contentTertiary text-contentTertiary',
    },
  },
});

interface InsightTabButtonProps {
  isActive?: boolean;
  onClick: (tab: InsightTab) => void;
  tab: InsightTab;
}

const InsightTabButton: React.FC<InsightTabButtonProps> = ({
  isActive = false,
  onClick,
  tab,
}) => {
  const className = insightTabButtonStyles({ active: isActive });
  return (
    <button className={className} onClick={() => onClick(tab)}>
      {InsightTabLabels[tab]}
    </button>
  );
};

// Public Components

export const AppointmentInsights: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<InsightTab>('RED_FLAGS');
  const apiClient = useApiClient();
  const { appointmentId, tenantId } = useAppointmentContext();
  const appointmentStatus = useAppointmentStatus();

  const [insertFeedback] = useMutation(insertFeedbackMutation);

  const { appointmentLlmOutput: redFlagsOutput, refetch } =
    useLatestAppointmentLlmOutput({
      outputType: LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS,
    });

  const { status } = useListenForAppointmentLlmOutputStatus({
    outputType: LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS,
    shouldSubscribe: appointmentStatus === 'ACTIVE',
  });

  useListenForAppointmentLlmOutputs({
    outputType: LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS,
    lastOutputCreatedAt: redFlagsOutput?.createdAt ?? null,
    onDataReceived: refetch,
  });

  const insights = redFlagsOutput?.content
    ? (redFlagsOutput.content as RedFlagsOutputSchema)
    : null;

  const hasContent = insights != null && insights.red_flags.length > 0;

  const isGenerating = status === WorkerStatusTypesLookup.IN_PROGRESS;

  const handleClick = async () => {
    try {
      const result = await apiClient.llm.generateAppointmentOutput({
        params: { tenantId, appointmentId },
        body: { outputType: 'DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS' },
      });

      if (result.status !== 201) {
        toast.error(
          'We could not generate your insights. Please try again later!',
        );
        return;
      }
    } catch (e) {
      toast.error(
        'We could not generate your insights. Please try again later!',
      );
    }
  };

  const handleFeedback = async (isHelpful: boolean, content: string) => {
    await insertFeedback({
      variables: {
        appointmentId,
        summarySection: content,
        type: isHelpful
          ? FeedbackTypeLookup.POSITIVE
          : FeedbackTypeLookup.NEGATIVE,
        llmOutputId: redFlagsOutput?.llmOutputId,
      },
    });
  };

  if (!hasContent) {
    return (
      <HStack className="mb-4 mt-2 rounded-lg bg-brandGray100 p-3 pr-4" gap={3}>
        <div className="rounded-md bg-purple p-2.5 text-purpleContrast">
          <Icon name="Lightbulb" size="sm" />
        </div>
        <VStack gap={0}>
          <P
            className={textStyles.body({
              weight: 'bold',
              size: 'l',
              className: 'text-contentSecondary',
            })}
          >
            Eluve Insights
          </P>
          <P
            className={textStyles.body({
              weight: 'medium',
              size: 's',
              className: 'text-contentTertiary',
            })}
          >
            Improve diagnostic accuracy and patient outcomes with AI-powered
            insights.
          </P>
        </VStack>
        {isGenerating ? (
          <Icon
            name="LoaderCircle"
            className="mr-2 animate-spin text-contentSecondary"
          />
        ) : (
          <NewButton
            onClick={handleClick}
            size="s"
            text="Run Insights"
            type="outline"
          />
        )}
      </HStack>
    );
  }

  return (
    <VStack
      align="stretch"
      className="mb-4 mt-2 rounded-lg border border-gray-5 bg-backgroundPrimary "
      gap={0}
    >
      <HStack
        className="h-[52px] rounded-t-[7px] bg-purpleContrast pl-3 pr-4"
        gap={3}
      >
        <div className="rounded-md bg-purple p-1.5 text-purpleContrast">
          <Icon name="Lightbulb" size="xs" />
        </div>
        <VStack>
          <P
            className={textStyles.body({
              weight: 'bold',
              size: 'l',
              className: 'text-purple',
            })}
          >
            Eluve Insights
          </P>
        </VStack>
        {isGenerating ? (
          <Icon name="LoaderCircle" className="mr-1 animate-spin text-purple" />
        ) : (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <NewButton
                  icon={{ name: 'RefreshCw' }}
                  onClick={handleClick}
                  size="s"
                  type="outline"
                />
              </TooltipTrigger>
              {!isGenerating && (
                <TooltipContent sideOffset={4}>Re-run Insights</TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        )}
      </HStack>
      <HStack className="border-b border-gray-5 px-4 pt-2" gap={4}>
        {Object.keys(InsightTabLabels).map((tab) => (
          <InsightTabButton
            key={tab}
            isActive={currentTab === tab}
            onClick={setCurrentTab}
            tab={tab as InsightTab}
          />
        ))}
      </HStack>
      {currentTab === 'RED_FLAGS' && (
        <div className="py-4">
          <RedFlagsOutput
            content={insights.red_flags}
            onFeedback={handleFeedback}
            onShowDiagnoses={() => setCurrentTab('DIFFERENTIAL_DIAGNOSES')}
          />
        </div>
      )}
      {currentTab === 'DIFFERENTIAL_DIAGNOSES' && (
        <div className="p-6">
          <DifferentialDiagnosesOutput
            content={insights.differential_diagnoses}
            showTitle={false}
            onFeedback={handleFeedback}
          />
        </div>
      )}
    </VStack>
  );
};
