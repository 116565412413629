import { useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import {
  WorkerStatusTypesEnum,
  WorkerStatusTypesLookup,
} from '@eluve/graphql-types';

import { useAppointmentContext } from './appointment.context';
import { listenForAppointmentLlmOutputStatusSubscription } from './insights.operations';
import { SupportedOutputTypes } from './useLatestAppointmentLlmOutput';

export const useListenForAppointmentLlmOutputStatus = ({
  outputType,
  shouldSubscribe = true,
}: {
  outputType: SupportedOutputTypes;
  shouldSubscribe?: boolean;
}) => {
  const { tenantId, appointmentId } = useAppointmentContext();
  const [jobStatus, setJobStatus] = useState<WorkerStatusTypesEnum | undefined>(
    undefined,
  );

  const { data } = useSubscription(
    listenForAppointmentLlmOutputStatusSubscription,
    {
      variables: {
        tenantId,
        appointmentId,
        outputType,
      },
      skip: !shouldSubscribe,
    },
  );

  const observedStatus =
    data?.appointmentsByPk?.appointment_llm_jobs?.[0]?.jobStatus;
  const prevObservedStatus = usePrevious(observedStatus);

  useEffect(() => {
    if (
      prevObservedStatus === WorkerStatusTypesLookup.IN_PROGRESS &&
      observedStatus === WorkerStatusTypesLookup.COMPLETED
    ) {
      // Delay 1 seconds before letting the UI know the job is completed
      const timer = setTimeout(() => {
        setJobStatus(observedStatus);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setJobStatus(observedStatus);
    }
  }, [observedStatus, prevObservedStatus]);

  return {
    status: jobStatus,
  };
};
