import { useMutation } from '@apollo/client';
import { createStoreWithProducer } from '@xstate/store';
import { useSelector } from '@xstate/store/react';
import { produce } from 'immer';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';
import ReactJson from 'react-json-view';
import { VariantProps } from 'tailwind-variants';
import { match } from 'ts-pattern';

import { AudioPlayerHandle } from '@eluve/audio-player';
import { TooltipLabel, TranscriptTimestampDivider } from '@eluve/blocks';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  FCC,
  HStack,
  NewButton,
  P,
  VStack,
  textStyles,
  tv,
} from '@eluve/components';
import { FragmentOf } from '@eluve/graphql.tada';
import { useUserIdFromSession } from '@eluve/session-helpers';
import { EluveAdminOnly } from '@eluve/smart-blocks';

import { SegmentAudioControls } from './SegmentAudioControls';
import { segmentFragment, updateUtteranceTextMutation } from './operations';
import { useSegmentAudioData } from './useTranscriptAudioAnalysisData';

const transcriptConfidenceStyles = tv({
  base: '',
  variants: {
    confidence: {
      10: 'opacity-100',
      9: 'opacity-90',
      8: 'opacity-80',
      7: 'opacity-70',
      6: 'opacity-60',
      5: 'opacity-50',
      4: 'opacity-40',
      3: 'opacity-30',
      2: 'opacity-20',
    },
  },
});

type TranscriptConfidenceStyleVariantProps = VariantProps<
  typeof transcriptConfidenceStyles
>;

const createSegmentStore = () => {
  return createStoreWithProducer(produce, defaultContext, {
    updateTimestamp: (ctx, evt: { timestamp: number }) => {
      ctx.currentTimestamp = evt.timestamp;
    },
  });
};

type SegmentStore = ReturnType<typeof createSegmentStore>;

type SegementAudioContextValue = {
  store: SegmentStore;
  audioPlayerHandle: React.RefObject<AudioPlayerHandle>;
};

const SegmentAudioContext =
  React.createContext<SegementAudioContextValue | null>(null);

export const useSegmentAudioContext = () => {
  const context = React.useContext(SegmentAudioContext);
  if (context === null) {
    throw new Error(
      'useSegmentAudioContext must be used within a SegmentAudioContextProvider',
    );
  }
  return context;
};

type SegmentAudioContextProviderProps = {
  index: number;
  tenantId: string;
  appointmentId: string;
  seg: FragmentOf<typeof segmentFragment>;
  isReadonly?: boolean;
};

const defaultContext = {
  currentTimestamp: 0,
};

const TranscriptWord: React.FC<{
  className?: string;
  showConfidenceStyles: boolean;
  w: {
    confidence: number | null;
    punctuated_word?: string;
    word: string;
    start: number | null;
    end: number | null;
  };
}> = ({ w, showConfidenceStyles, className }) => {
  const { audioPlayerHandle } = useSegmentAudioContext();

  return (
    <TooltipLabel
      label={`Word: "${w.punctuated_word ?? w.word}", Confidence: ${w.confidence}`}
    >
      <span
        onClick={() => {
          if (w.start === null) return;
          audioPlayerHandle?.current?.seekTo(w.start);
        }}
        className={textStyles.body({
          className: [
            className,
            'inline-flex cursor-pointer hover:underline',
            showConfidenceStyles && w.confidence
              ? transcriptConfidenceStyles({
                  confidence: Math.floor(
                    w.confidence * 10,
                  ) as TranscriptConfidenceStyleVariantProps['confidence'],
                })
              : '',
          ],
        })}
      >
        {w.punctuated_word ?? w.word}
      </span>
    </TooltipLabel>
  );
};

const MemoizedTranscriptWord = React.memo(TranscriptWord, isEqual);

export const SegmentAudioContextProvider: FCC<
  SegmentAudioContextProviderProps
> = ({ appointmentId, seg, tenantId, index, isReadonly = false }) => {
  const userId = useUserIdFromSession();
  const showConfidenceStyles = true;

  const store = useMemo(() => createSegmentStore(), []);
  const audioPlayerRef = useRef<AudioPlayerHandle>(null);

  const [updateUtterance] = useMutation(updateUtteranceTextMutation, {});

  const onUtteranceChanged = (id: string, newUtteranceText: string) => {
    if (isReadonly) return;
    updateUtterance({
      variables: {
        tenantId,
        id,
        text: newUtteranceText,
      },
      optimisticResponse: () => ({
        updateUtterancesByPk: {
          __typename: 'Utterances',
          id,
          text: newUtteranceText,
          updatedById: userId,
        },
      }),
    });
  };

  const value: SegementAudioContextValue = useMemo(
    () => ({
      store,
      audioPlayerHandle: audioPlayerRef,
    }),
    [store],
  );

  const timestamp = useSelector(
    store,
    (state) => state.context.currentTimestamp,
  );

  const { isLoading, result } = useSegmentAudioData({
    appointmentId,
    seg,
    tenantId,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (result.type === 'no_audio') {
    return <div>No audio available</div>;
  }

  const ctx = result.context;

  return (
    <SegmentAudioContext.Provider value={value}>
      <VStack className="sticky top-0 z-10 bg-white">
        <TranscriptTimestampDivider
          timestamp={ctx.startedAt}
          action={index === 0 ? 'started' : 'resumed'}
        />
        {ctx.audioUrl && <SegmentAudioControls audioUrl={ctx.audioUrl} />}
        <EluveAdminOnly>
          {!isEmpty(ctx.rawMetadata) && (
            <Dialog>
              <DialogTrigger asChild>
                <NewButton type="outlineFilled" text="Raw Metadata" />
              </DialogTrigger>
              <DialogContent className="max-w-[90vw]">
                <VStack className="max-h-[600px] w-full overflow-scroll">
                  <ReactJson enableClipboard={false} src={ctx.rawMetadata} />
                </VStack>
              </DialogContent>
            </Dialog>
          )}
        </EluveAdminOnly>
      </VStack>
      <VStack className="grid max-h-[600px] grid-cols-[min-content_min-content_1fr] gap-x-5 overflow-scroll">
        {seg.utterances.map((u, uIdx) => {
          const showSpeaker =
            u.speaker &&
            (uIdx === 0 || u.speaker !== seg.utterances[uIdx - 1]?.speaker);
          return (
            <React.Fragment key={u.id}>
              {showSpeaker && (
                <P
                  className={textStyles.body({
                    size: 'l',
                    className: 'whitespace-nowrap underline',
                  })}
                >
                  {u.speaker}
                </P>
              )}

              <P
                className={textStyles.body({
                  className: `col-start-2 cursor-pointer hover:underline ${
                    timestamp >= (u.startAt ?? -1) &&
                    timestamp <= (u.endAt ?? -1)
                      ? 'bg-orange text-orangeContrast'
                      : ''
                  }`,
                  color: 'tertiary',
                })}
                onClick={() => {
                  if (u.startAt) {
                    audioPlayerRef?.current?.seekTo(u.startAt);
                  }
                }}
              >
                {u.startAt?.toFixed(2)}
              </P>

              <HStack
                gap={1}
                wrap
                className="col-start-3"
                contentEditable={!isReadonly}
                suppressContentEditableWarning
                onBlur={(e) => {
                  const newUtterance = e.target.innerText.split('\n').join(' ');

                  if (newUtterance !== u.text) {
                    onUtteranceChanged(u.id, newUtterance);
                  }
                }}
              >
                {match(u.updatedById)
                  .with(null, () => (
                    <HStack gap={1} wrap>
                      {u.fragments.map((f, fIdx) => (
                        <MemoizedTranscriptWord
                          key={fIdx}
                          showConfidenceStyles={showConfidenceStyles}
                          w={{
                            confidence: f.confidence,
                            end: f.endAt,
                            start: f.startAt,
                            word: f.text,
                          }}
                          className={
                            timestamp >= (f.startAt ?? -1) &&
                            timestamp <= (f.endAt ?? -1)
                              ? 'bg-orange text-orangeContrast'
                              : ''
                          }
                        />
                      ))}
                    </HStack>
                  ))
                  .otherwise(() => (
                    <P
                      className={textStyles.body({
                        className: 'text-positive950',
                      })}
                    >
                      {u.text}
                    </P>
                  ))}
              </HStack>
            </React.Fragment>
          );
        })}

        {ctx.pausedAt && (
          <TranscriptTimestampDivider
            className="col-span-3"
            timestamp={ctx.pausedAt}
            action="paused"
          />
        )}
      </VStack>
    </SegmentAudioContext.Provider>
  );
};
