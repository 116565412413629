import zodToJsonSchema from 'zod-to-json-schema';

import {
  ExternalArtifactTypesEnum,
  LlmOutputTypesEnum,
  PatientArtifactTypesEnum,
} from '@eluve/graphql-types';

import { patientOverviewSchema } from './patient-overview.schema';
import { redFlagsOutputSchema } from './red-flags.schema';

const patientArtifactsDescriptions = {
  intake_form:
    'Patient intake form. This will only be used if no previous patient overview is available. This form is imported from EHR and does not have a consistent schema. Comes from external_patient_artifacts table.',
  lab_results:
    'Results from laboratory tests. This does not have a consistent schema at the moment. Comes from external_patient_artifacts table.',
  temp_medical_notes:
    'Any temporary medical notes. These are stored in external_patient_artifacts table.',
  all_medical_notes:
    'Signed collection of all medical notes for the patient. These are signed human output for the patient. The value gets populated dynamically during prompt building.',
  latest_medical_note:
    'Most recent signed medical note. The value gets populated dynamically during prompt building.',
  patient_details:
    'Basic patient profile information. Includes sex and date of birth, as well as raw data imported from EHR. The data is available in external_patients_info table.',
  patient_overview:
    'Current patient overview. The value is obtained from patient_overviews table.',
} as const satisfies Record<
  Lowercase<ExternalArtifactTypesEnum | PatientArtifactTypesEnum>,
  string
>;

const patientOverviewDescriptions: Record<string, string> = {
  ...patientArtifactsDescriptions,
  schema: JSON.stringify(zodToJsonSchema(patientOverviewSchema), undefined, 2),
};

const redFlagsDescriptions = {
  ...patientArtifactsDescriptions,
  schema: JSON.stringify(zodToJsonSchema(redFlagsOutputSchema), undefined, 2),
};

export const LLM_OUTPUT_TYPE_VARIABLES: Record<LlmOutputTypesEnum, string[]> = {
  SOAP_NOTE: [
    'transcript',
    'schema',
    ...Object.keys(patientArtifactsDescriptions),
  ],
  FACT_VERIFICATION: ['summary', 'statement'],
  CLIENT_RECAP: ['transcript'],
  BILLING_CODE_RECOMMENDATION: ['transcript', 'schema'],
  DIFFERENTIAL_DIAGNOSES: ['transcript', 'schema'],
  GENERIC_OUTPUT: [],
  REVIEW_OF_SYSTEMS: ['transcript', 'schema'],
  PFSH: ['transcript', 'schema'],
  FACT_GENERATION: ['artifactContent', 'llmNote', 'humanEditedNote', 'schema'],
  CHIEF_COMPLAINT: ['transcript', 'schema'],
  PII_REMOVAL: ['text', 'schema'],
  DYNAMIC_OUTPUT: [
    'transcript',
    'schema',
    ...Object.keys(patientArtifactsDescriptions),
  ],
  TRANSLATION: ['content', 'inputLanguage', 'outputLanguage', 'schema'],
  MEDICINE_RECOMMENDATION: ['transcript', 'schema'],
  PATIENT_OVERVIEW: Object.keys(patientOverviewDescriptions),
  DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS: [
    'transcript',
    ...Object.keys(redFlagsDescriptions),
  ],
};

export const LLM_OUTPUT_TYPE_DESCRIPTIONS: Partial<
  Record<LlmOutputTypesEnum, Record<string, string>>
> = {
  PATIENT_OVERVIEW: patientOverviewDescriptions,
  SOAP_NOTE: patientArtifactsDescriptions,
  DYNAMIC_OUTPUT: patientArtifactsDescriptions,
  DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS: redFlagsDescriptions,
};
