import { useMutation } from '@apollo/client';
import React from 'react';

import { P, VStack, textStyles } from '@eluve/components';
import {
  useAppointmentContext,
  useLatestAppointmentLlmOutput,
  useListenForAppointmentLlmOutputs,
} from '@eluve/frontend-appointment-hooks';
import { DifferentialDiagnosesOutput } from '@eluve/frontend-feature-appointment';
import { FeedbackTypeLookup, LlmOutputTypesLookup } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { DifferentialDiagnosesOutputSchema } from '@eluve/llm-outputs';

const insertFeedbackMutation = graphql(`
  mutation insertSummaryFeedback(
    $appointmentId: uuid!
    $llmOutputId: uuid
    $type: FeedbackTypeEnum!
    $summarySection: String
  ) {
    insertSummaryFeedbackOne(
      object: {
        appointmentId: $appointmentId
        llmOutputId: $llmOutputId
        type: $type
        summarySection: $summarySection
      }
    ) {
      id
      type
      __typename
    }
  }
`);
export const DifferentialDiagnosesComponent: React.FC = () => {
  const { appointmentId } = useAppointmentContext();

  const { appointmentLlmOutput: ddxOutput, refetch: refetchDDX } =
    useLatestAppointmentLlmOutput({
      outputType: LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES,
    });

  useListenForAppointmentLlmOutputs({
    outputType: LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES,
    lastOutputCreatedAt: ddxOutput?.createdAt ?? null,
    onDataReceived: refetchDDX,
  });

  const [insertFeedback] = useMutation(insertFeedbackMutation);

  const handleFeedback = async (isHelpful: boolean, diagnosis: string) => {
    await insertFeedback({
      variables: {
        appointmentId,
        summarySection: diagnosis,
        type: isHelpful
          ? FeedbackTypeLookup.POSITIVE
          : FeedbackTypeLookup.NEGATIVE,
        llmOutputId: ddxOutput?.llmOutputId,
      },
    });
  };

  const ddx = ddxOutput?.content
    ? (ddxOutput.content as DifferentialDiagnosesOutputSchema)
    : null;

  if (!ddx) {
    return null;
  }

  return (
    <VStack>
      <P
        className={textStyles.title({
          weight: 'semibold',
          size: 'l',
          className: 'text-nowrap',
        })}
      >
        Differential Diagnoses
      </P>
      <div className="w-full rounded-lg border border-brandGray200 bg-white p-6">
        <DifferentialDiagnosesOutput
          content={ddx!}
          showTitle={false}
          onFeedback={handleFeedback}
        />
      </div>
    </VStack>
  );
};
