import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { LlmOutputTypesEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';

import { BillingCodeOutput } from './llm-outputs/BillingCodeOutput';
import { DifferentialDiagnosesOutput } from './llm-outputs/DifferentialDiagnosesOutput';
import {
  NO_OUTPUT_ID,
  NoOutputAvailable,
} from './llm-outputs/NoOutputAvailable';
import { PfshOutput } from './llm-outputs/PfshOutput';
import { ReviewOfSystemsOutput } from './llm-outputs/ReviewOfSystemsOutput';
import { SoapNoteOutput } from './llm-outputs/SoapNoteOutput';

export const getAppointmentOutputQuery = graphql(`
  query getAppointmentOutput(
    $appointmentId: uuid!
    $outputType: LlmOutputTypesEnum!
  ) {
    appointmentLlmOutputs(
      where: {
        appointmentId: { _eq: $appointmentId }
        output: {
          modelPromptTemplate: {
            template: { outputType: { _eq: $outputType } }
          }
        }
      }
      limit: 1
      orderBy: { output: { startedAt: DESC } }
    ) {
      __typename
      llmOutputId
      output {
        __typename
        id
        content
      }
    }
  }
`);

export interface AppointmentDetailsOutputProps {}

export const AppointmentDetailsOutput: React.FC<
  AppointmentDetailsOutputProps
> = () => {
  const { tenantId, appointmentId, outputType } = useParams() as {
    tenantId: string;
    appointmentId: string;
    outputType: LlmOutputTypesEnum;
  };

  // TODO(jesse)[ELU-1092]: Need to also pull in human outputs here
  const { data, refetch } = useSuspenseQuery(getAppointmentOutputQuery, {
    variables: {
      appointmentId,
      outputType,
    },
  });

  if (!data.appointmentLlmOutputs.length) {
    return (
      <div id={NO_OUTPUT_ID}>
        <NoOutputAvailable
          tenantId={tenantId}
          appointmentId={appointmentId}
          outputType={outputType}
          refetch={refetch}
        />
      </div>
    );
  }

  const output = data.appointmentLlmOutputs[0]?.output?.content ?? {};

  return match(outputType)
    .with('SOAP_NOTE', () => <SoapNoteOutput content={output} />)
    .with('DIFFERENTIAL_DIAGNOSES', () => (
      <DifferentialDiagnosesOutput content={output} showTitle={true} />
    ))
    .with('PFSH', () => <PfshOutput content={output} />)
    .with('BILLING_CODE_RECOMMENDATION', () => (
      <BillingCodeOutput content={output} />
    ))
    .with('REVIEW_OF_SYSTEMS', () => <ReviewOfSystemsOutput content={output} />)
    .with('CHIEF_COMPLAINT', () => (
      <div>
        <pre>{JSON.stringify(output, null, 2)}</pre>
      </div>
    ))
    .otherwise((val) => <div>{`Renderer for ${val} not implemented`}</div>);
};
